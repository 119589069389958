/* Basic styling */

body {
  width: 100%;
}

h1, h4, h6, .title {
  font-family: 'Elsie', cursive;
}

footer {
  left: auto;
  right: auto;
  margin: 1%;
  text-align: center;
}

.title {
  margin-left: -0.5em;
  font-weight: bold;
}

/* Navbar */

.navbar-nav {
  text-align: center;
}

.nav-link, .nav-item {
  font-family: 'Varela Round', sans-serif;
  margin: -1% 0;
  font-size: 1.5em;
}

.nav-item, li, h5, p {
  font-family: 'Varela Round', sans-serif;
}

.welcome, .row {
  align-content: center;
}

/* Main component styling */

.parallax-bg {
  height: 60%;
	/* Create the parallax scrolling effect */
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./images/puppy1.JPG');
  background-position-y: .2em;
  opacity: .7;
}

.main-title-box {
  margin: 2em 6em;
  color: #333;
  text-align: end;
  white-space: nowrap;
}
.main-title {
  font-family: 'Rouge Script', cursive;
  font-size: 4em;
}

/* .intro-box {
  width: 20%;
} */

.content-container {
  display: flex;
  flex-direction: row;
}

.announcement, .links, .card {
  margin-top: .4em;
}

.announcement-text {
  font-size: 1.3em;
}

.empty {
  padding: 2px;
}

.links, .welcome-msg {
  background-color: #fff;
}

.welcome-msg {
  /* margin: .5em; */
  opacity: .9;
  min-height: 15rem;
  /* max-height: 100%; */
}

.welcome-blurb {
  min-height: 15rem;
  /* max-height: 16rem; */
  overflow: auto;
}

.coming-soon {
  margin: 1em 0.5em;
  padding: 1em;
  border: double gray 10px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.34902) 0px 5px 15px;
}

.card {
  /* width: 18rem; */
  border-radius: 20px;
  margin-right: auto;
  margin-left: auto;
  /* clear: both; */
}

.card-img-top {
  width: 20em !important;
  margin-right: auto;
  margin-left: auto;
}

.card-row {
  /* display: flex; */
  /* flex-direction: row; */
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
  align-content: center;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 991px) {
  .card-row {
      display: flex;
      flex-direction: column;
  }
  /* .video-player {
    padding: 1em 1em 6em 1em;
    height: fit-content;
  } */
}

.announce-pic {
  border: 7px solid #fff;
  border-radius: 20px;
  opacity: .9;
}

.Main {
  margin-top: -4em;
}

.together {
  width: 100%;
  max-width: 700px;
  border: 2px solid #fff;
  border-radius: 20px;
  opacity: .9;
}

.lineup-row {
  /* background-color: #4A4A4B; */
  width: 30em;
  box-shadow: 1px 2px 10px 1px #777;
  padding: 1em;
  margin: .5em;
  text-align: center;
}

.updates-blog {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2em;
}

.awards {
  padding-left: 0;
  text-align: center;
}

.thumbnail-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2em;
}

.video-player {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  padding: 1em 1em 2em 1em;
  background-color: rgb(255, 255, 255);
  width: fit-content;
  height: 35em;
  text-align: center;
  box-shadow: 1px 2px 10px 1px #777;
  font-family: Arial, Helvetica, sans-serif;
}

.react-player {
  margin: auto;
}

.puppy-lineup {
  opacity: 1;
  /* height: 20em; */
}

.lineup {
  width: 25%;
  border: 2px solid #fff;
  border-radius: 10px;
}

.puppy-pumpkin {
  width: 40%;
  border: 8px solid #fff;
  border-radius: 20px;
  opacity: .9;
  opacity: .9;
}

.bold {
  font-weight: bolder;
}

li {
  list-style: none;
  margin-left: -0.1em;
}

a {
  color: #7C7C7D;
}

a:hover {
  color: #4A4A4B;
  text-decoration: none;
}

.display {
  align-items: center;
  height: auto;
  color: black;
  /* background-color: #f2f2f2; */
  /* background-image: url(./images/tapestry.png); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% 100%;
}

.carousel-inner {
  border: 10px solid #fff;
  border-radius: 20px;
}

.slider {
  text-align: center;
}

.slider-wrapper {
  background-color: #f2f2f2;
  padding: 1em;
  max-width: 50em;
  margin-right: auto;
  margin-left: auto;
}

.welcome, .Application, .Contact {
  align-content: center;
  align-items: center;
}

.welcome-msg, .links {
  border: 10px solid #fff;
  border-radius: 20px;
}

.btn-secondary {
  align-self: center;
  border-radius: 20px;
}

.playing-image, .green-toy, .puppy-bio-image {
  border: 10px solid #fff;
  border-radius: 20px;
  opacity: .9;
}

/* hiding in case we need it for later */

/* .puppy-name {
  font-weight: bold;
  font-size: 110%;
  font-family: 'Varela Round', sans-serif;
} */

/* Styling for slideshow */

.d-block {
  height: auto;
}

.picholder {
  background-color: #7C7C7D;
  /* min-height: 470px; */
  max-height: 478px;
  display: flex;
  /* align-content: center; */
  vertical-align: middle;
  margin: auto;
}

.portrait {
  margin: 0 auto;
  width: auto;
  min-height: auto;
  max-height: 478px;
}

/* Fading animation */

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes fade {
  from {
    opacity: .4
  }
  to {
    opacity: 1
  }
}

@keyframes fade {
  from {
    opacity: .4
  }
  to {
    opacity: 1
  }
}

/* Styling for contact and application forms */

.Application {
  font-size: 1.2em;
}

form {
  margin: 0.1em;
}

textarea {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  resize: none;
}

.select {
  width: 10%;
  /* margin: 0 -10 0 10%; */
}

.text-input, label {
  width: 90%;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
}

.new-q {
  margin-top: 0.04em;
}

label {
  /* margin-bottom: -1%; */
  font-family: 'Varela Round', sans-serif;
}

textarea, .text-input {
  resize: none;
}

.survey-question, p {
  margin: 0.5em;
  font-family: 'Varela Round', sans-serif;
}

.select:focus
/* input:valid, */

input:focus,
/* textarea:valid, */

textarea:focus {
  border-bottom: 2px solid #4e899c;
  resize: none;
}

.input-pair {
  display: flex;
  flex-direction: row;
}

.input-pair-checkbox, .input-pair-radio {
  margin: 0.5em;
}

/* Mobile view optimization */

@media (min-width: 768px) {
  .display {
    margin: 7em .1em;
  }
  .puppy-bio-image {
    margin: 0.02em;
    width: 100%;
  }
  .picholder {
    background-color: #7C7C7D;
    min-height: 300px;
    max-height: 478px;
  }
}

@media (min-width: 1088px) {
  .display {
    margin: 7em .1em;
  }
  .picholder {
    background-color: #7C7C7D;
    min-height: 478px;
    max-height: 18em;
  }
}

@media (max-width: 1300px) {
  .content-container {
    display: flex;
    flex-direction: column;
  }
  .lineup-row {
    width: 100%;
    box-shadow: none;
  }
  
  .welcome-blurb {
    font-size: 1.3em;
    min-height: 5em;
  }
}

@media (max-width: 800px) { /* 500px */
  .parallax-bg {
    display: none;
  }
  .display {
    margin: 7em 0;
  }
  .content-container {
    display: flex;
    flex-direction: column;
  }
  .lineup-row {
    width: 100%;
    box-shadow: none;
  }
  .main-title-box {
    margin: 0.5em;
    text-align: center;
  }
  .announcement {
    text-align: center;
  }
  /* .main-title, .main-subtitle {
    font-size: 1em;
  } */
}
