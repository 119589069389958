.thumbnail {
    cursor: pointer;
    width: 3em;
    margin: 0.5em;
}

.thumbnail-display-full {
    cursor: pointer;
    max-width: 3em;
    max-height: 3em;
    margin: 0.5em;
}

.picture-modal {
    position: fixed;
    z-index: 100000010;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 5px 5px 20px #222;
    width: 40em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1em;
}

.picture-display-full {
    margin: 0 auto 6em auto;
    max-height: 55em;
    border: double 1px black;
    overflow: hidden;
    border-radius: 10px;
    padding: 20px;
}

.modal-image {
    width: 100%;
}

.modal-image-display-full {
    max-height: 50em;
    max-width: 50em;
}

.x-box {
    font-weight: bolder;
    z-index: 100000020;
    font-size: 1em;
    background-color: #fff;
    padding: 0.1 0.5em;
    margin: -1em -1em 0 auto;
    top: -10px;
    cursor: pointer;
    text-align: right;
}

.picture-and-arrows {
    display: flex;
    flex-direction: row;
}

.arrow {
    border: solid black;
    border-width: 0 10px 10px 0;
    display: inline-block;
    padding: 3px;
    height: 3em;
    width: 3em;
    opacity: 0.5;
    position: fixed;
    margin: auto 0;
    cursor: pointer;
  }

  .arrow-display-full {
    border: solid black;
    border-width: 0 10px 10px 0;
    display: inline-block;
    padding: 3px;
    height: 2em;
    width: 2em;
    opacity: 0.5;
    margin: 2em auto;
    cursor: pointer;
  }

.arrow:hover .arrow-display-full:hover {
    opacity: 1;
}
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    right: 1em;
    top: 50%;
  }
  
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    left: 1em;
    top: 50%;
  }

  .right-display-full {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    right: 1em;
    margin: auto 0;
  }
  
  .left-display-full {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin: auto 0;
  }

  @media (max-width: 1100px) {
    .picture-modal {
        max-width: 45em;
    }
    .modal-image-display-full {
        max-height: 45em;
        max-width: 35em;
    }
    .picture-display-full {
        margin: 0 auto 2em auto;
        max-height: 50em;
        border: double 1px black;
        overflow: hidden;
        border-radius: 10px;
        padding: 20px;
    }
}

@media (max-width: 700px) {
    .picture-modal {
        max-width: 30em;
    }
    .modal-image-display-full {
        max-height: 30em;
        max-width: 20em;
    }
    .picture-display-full {
        margin: 0 auto 4em auto;
        max-height: 30em;
        border: double 1px black;
        overflow: hidden;
        border-radius: 10px;
        padding: 20px;
    }
}

@media (max-width: 530px) {
    .picture-modal {
        max-width: 25em;
    }
}